import { reducer as formReducer } from "redux-form";
import mainUserReducer from "./mainUserReducer";
import configurationReducer from "./configurationReducer";
import clientsReducer from "./clientsReducer";
import globalAlertReducer from "./globalAlertReducer";
import projectsReducer from "./projectsReducer";
import reservationsReducer from "./reservationsReducer";
import onnaDevicesReducer from "./onnaDevicesReducer";
import apartmentsReducer from "./apartmentsReducer";
import knxAddressReducer from "./knxAddressReducer";
import notificationsReducer from "./notificationsReducer";
import settingsReducer from "./settingsReducer";
import macroReducer from "./macroReducer";
import scheduleReducer from "./scheduleReducer";
import systemStateReducer from "./systemStateReducer";
import trendsReducer from "./trendsReducer";
import cloudApisReducer from "./cloudApisReducer";
import occupancyReducer from "./occupancyReducer";
import thermostatIPReducer from "./thermostatIPReducer";

const reducers = {
  mainUserReducer,
  clientsReducer,
  globalAlertReducer,
  projectsReducer,
  reservationsReducer,
  configurationReducer,
  settingsReducer,
  macroReducer,
  scheduleReducer,
  systemStateReducer,
  onnaDevicesReducer,
  apartmentsReducer,
  knxAddressReducer,
  notificationsReducer,
  trendsReducer,
  cloudApisReducer,
  occupancyReducer,
  thermostatIPReducer,
  form: formReducer,
};

export default reducers;
