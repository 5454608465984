import constants from "../constants";

const {
  SETTINGS_SAVE_DATA,
  SETTINGS_SEND_DATA,
  SEND_CHANGE_OPEN_STATE,
  SEND_REMOVE_USER_LIST,
  SEND_DATE_TIME,
  GET_DATE_TIME,
  REBOOT_ONNA,
  UPDATE_APP,
  GET_IP_ADDRESS,
  SEND_PROTECT_SETTINGS_PAGE,
} = constants;

export const actionSettingsSaveData = (data) => ({
  type: SETTINGS_SAVE_DATA,
  data,
});

export const actionSettingsSendData = (data) => ({
  type: SETTINGS_SEND_DATA,
  data,
});

export const sendChangeOpenState = (data) => ({
  type: SEND_CHANGE_OPEN_STATE,
  data,
});

export const sendRemoveUserList = (user) => ({
  type: SEND_REMOVE_USER_LIST,
  user,
});

export const sendDateTime = (data) => ({
  type: SEND_DATE_TIME,
  data,
});

export const getDateTime = (cb) => ({
  type: GET_DATE_TIME,
  cb,
});

export const sendUpdateApp = (data) => ({
  type: UPDATE_APP,
  data,
});

export const sendRebootOnna = () => ({
  type: REBOOT_ONNA,
});

export const sendGetIpAddress = () => ({
  type: GET_IP_ADDRESS,
});

export const actionProtectSettingsPage = (data, pin) => ({
  type: SEND_PROTECT_SETTINGS_PAGE,
  protectSettingsPage: data,
  pin,
});
