/**
Device1: 'On/Off Lamp',
Device2: 'Dimming Lamp',
Device3: 'Color Lamp 1 byte',
Device4: 'Color Lamp 3 bytes',
Device5: 'Shutter/Venetian',
Device6: 'Label Boolean',
Device7: 'Label Analog',
Device8: 'Custom Boolean',
Device9: 'Custom Analog',
Device10: 'Thermostat',
Device11: 'Alert',
Device12: 'Consumption',
Device15: 'ThermostatIp',
 */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import { history } from "../../../store/configureStore";
import { sendValueServer } from "../../../store/actions/knxAddress";

import CardDevice1 from "./cardDevices/CardDevice1";
import CardDevice2 from "./cardDevices/CardDevice2";
import CardDevice5 from "./cardDevices/CardDevice5";
import CardDevice6 from "./cardDevices/CardDevice6";
import CardDevice7 from "./cardDevices/CardDevice7";
import CardDevice8 from "./cardDevices/CardDevice8";
import CardDevice9 from "./cardDevices/CardDevice9";
import CardDevice10 from "./cardDevices/CardDevice10";
import CardDevice12 from "./cardDevices/CardDevice12";
import CardDevice14 from "./cardDevices/CardDevice14";
import CardDevice15 from "./cardDevices/CardDevice15";

function DeviceCreator({ device, sendValueServerDispatch, addresses }) {
  const { type } = device;

  const shutterDeviceState = () => {
    if (device.value || device.valueState) {
      return addresses.find((address) => address.id === device.valueStateAddress)?.value;
    }
    return addresses.find((address) => address.id === device.moveAddress)?.value;
  };

  switch (type) {
    case "device1": // On/Off Lamp
      return (
        <CardDevice1
          device={device}
          sendValueServer={sendValueServerDispatch}
          deviceState={addresses.find((address) => address.id === device.onOffStateAddress)?.value}
        />
      );
    case "device2": // Value Lamp
      return (
        <CardDevice2
          device={device}
          sendValueServer={sendValueServerDispatch}
          deviceState={addresses.find((address) => address.id === device.valueStateAddress)?.value}
        />
      );
    case "device5": // Shutter
      return (
        <CardDevice5
          device={device}
          sendValueServer={sendValueServerDispatch}
          deviceState={shutterDeviceState()}
        />
      );
    case "device6": // Bool Label
      return (
        <CardDevice6
          device={device}
          deviceState={addresses.find((address) => address.id === device.onOffAddress)?.value}
        />
      );
    case "device7": // Analog Label
      return (
        <CardDevice7
          device={device}
          deviceState={addresses.find((address) => address.id === device.valueAddress)?.value}
        />
      );
    case "device8": // Bool Custom
      return (
        <CardDevice8
          device={device}
          deviceState={addresses.find((address) => address.id === device.onOffStateAddress)?.value}
          sendValueServer={sendValueServerDispatch}
        />
      );
    case "device9": // Analog Custom
      return (
        <CardDevice9
          device={device}
          deviceState={addresses.find((address) => address.id === device.valueStateAddress)?.value}
          sendValueServer={sendValueServerDispatch}
        />
      );
    case "device10": // Thermostat
      return <CardDevice10 device={device} sendValueServer={sendValueServerDispatch} />;
    case "device12": // Consumption
      return <CardDevice12 device={device} />;
    case "device14": // Consumption
      return <CardDevice14 device={device} />;
    case "device15": // ThermostatIp
      return <CardDevice15 device={device} sendValueServer={sendValueServerDispatch} />;
    default:
      return <div />;
  }
}

DeviceCreator.defaultProps = {
  device: {},
  addresses: [],
};

DeviceCreator.propTypes = {
  device: PropTypes.object,
  addresses: PropTypes.array,
  sendValueServerDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  sendValueServerDispatch: (id, address) => {
    dispatch(sendValueServer(id, address));
  },
});

const mapStateToProps = (state) => ({
  addresses: state.knxAddressReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCreator);
