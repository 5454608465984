/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, memo, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Custom Components
import Icons from "components/CustomIcons";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSlider from "components/MDSlider";
import MDButton from "components/MDButton";
import MDAppBar from "components/MDAppBar";
import { useMaterialUIController } from "context";
import { utilsDevice15 } from "utils/utilsDevice15";

function ModalDevice15(props) {
  const { device, thermostatIPReducer, dispatch } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  const nodeId = device?.nodeId;
  const thermostat = thermostatIPReducer?.thermostats?.[nodeId];

  const name = thermostat?.name || "";
  const isOnThermostatIp = thermostat?.onOff === 1;
  const modeActual = thermostat?.mode;
  const actualFanValue = thermostat?.fan;
  const realTemp = thermostat?.realTemp;
  const thermostatErrors = thermostat?.errors;
  const setpointActual = 0;

  const modesValues = thermostat?.modesValues;
  const speeds = thermostat?.speeds;
  const maxValue = thermostat?.maxValue;
  const minValue = thermostat?.minValue;
  const step = thermostat?.step;

  const [setpoint, setSetpoint] = useState(parseFloat(setpointActual));
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (thermostat) {
      setSetpoint(
        thermostat.setpoint === undefined || thermostat.setpoint === null
          ? thermostat.oldSetpoint === undefined
            ? thermostat.realTemp
            : thermostat.oldSetpoint
          : thermostat.setpoint
      );
    }
  }, [thermostat]);

  const modeIconName = utilsDevice15.getModeIcon(modeActual);
  // const modeIconColor = utilsDevice15.getModeIconColor(modeActual, isOnThermostatIp);

  const fanIconName = actualFanValue === 0 ? "FanAuto" : `Fan${actualFanValue}`;

  const dispatchValueChange = (property, value) => {
    dispatch({
      type: "CHANGE_THERMOSTATIP_VALUE",
      data: { nodeId, property, value },
    });
  };

  const getTabsInfo = () => [
    {
      label: `${t("temperature").toUpperCase()} ${t("setpoint").toUpperCase()}`,
    },
    { label: t("mode").toUpperCase() },
    { label: t("fan").toUpperCase() },
  ];

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgColor={isOnThermostatIp ? "info" : "white"}
        sx={({ palette: { background } }) => ({
          background: darkMode && !isOnThermostatIp && background.card,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" minWidth="150px">
            <Icons name="Thermometer" size={40} color={darkMode ? "white" : "black"} />
            <MDTypography color={isOnThermostatIp ? "white" : "text"}>{`${realTemp.toFixed(
              1
            )}ºC`}</MDTypography>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginLeft="5px"
            >
              {isOnThermostatIp ? (
                <>
                  <Icons name={modeIconName} size={40} color="white" />
                  <Icons name={fanIconName} size={40} color="white" />
                </>
              ) : (
                <Icons name="PowerOff" size={40} color={darkMode ? "white" : "black"} />
              )}
            </MDBox>
          </MDBox>
          <MDBox display="flex" sx={{ minWidth: 150 }} justifyContent="flex-end">
            <Switch
              checked={isOnThermostatIp}
              onChange={() => dispatchValueChange("onOff", isOnThermostatIp ? 0 : 1)}
            />
          </MDBox>
        </MDBox>

        <MDAppBar tabsInfo={getTabsInfo()} tabValue={tabValue} setTabValue={setTabValue} />

        {tabValue === 0 && (
          <MDBox textAlign="center">
            <MDSlider
              onChange={(value) => dispatchValueChange("setpoint", value)}
              value={parseFloat(setpoint)}
              color="error"
              min={parseFloat(minValue)}
              max={parseFloat(maxValue)}
              step={parseFloat(step)}
            />
            <MDTypography
              variant="h2"
              color={isOnThermostatIp ? "white" : "text"}
            >{`${setpoint.toFixed(1)}ºC`}</MDTypography>
          </MDBox>
        )}

        {tabValue === 1 && (
          <Grid container justifyContent="center">
            {utilsDevice15.getModeValuesAvailable(t, modesValues).map((item) => (
              <MDBox m={1} key={item.id}>
                <MDButton
                  color={item.value === modeActual ? "black" : "dark"}
                  onClick={() => dispatchValueChange("mode", item.value)}
                >
                  <MDTypography color="white">{t(item.label).toUpperCase()}</MDTypography>
                </MDButton>
              </MDBox>
            ))}
          </Grid>
        )}

        {tabValue === 2 && (
          <Grid container justifyContent="center">
            {utilsDevice15.getFanValuesAvailable(t, device.thermostatType, speeds).map((fan) => (
              <MDBox m={1} key={fan.id}>
                <MDButton
                  color={fan.value === actualFanValue ? "black" : "dark"}
                  onClick={() => dispatchValueChange("fan", fan.value)}
                >
                  <MDTypography color="white">{t(fan.label).toUpperCase()}</MDTypography>
                </MDButton>
              </MDBox>
            ))}
          </Grid>
        )}

        {thermostatErrors && thermostatErrors.length > 0 && (
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={0}
            lineHeight={1}
          >
            {thermostatErrors.map((error, index) => {
              const errorMessage = Object.values(error)[0];
              return (
                <MDTypography key={index} variant="body1" color="error">
                  {errorMessage || "ERROR DESCONOCIDO"}
                </MDTypography>
              );
            })}
          </MDBox>
        )}

        <MDBox mt={1} lineHeight={1}>
          <MDTypography
            variant="body2"
            textTransform="capitalize"
            color={isOnThermostatIp ? "white" : "text"}
          >
            {name.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ModalDevice15.propTypes = {
  device: PropTypes.object.isRequired,
  thermostatIPReducer: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ thermostatIPReducer: state.thermostatIPReducer || {} });

export default connect(mapStateToProps)(memo(ModalDevice15));
