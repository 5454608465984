/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";

// Custom Components
import Icons from "components/CustomIcons";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { utilsDevice15 } from "utils/utilsDevice15";
import ModalDevice15 from "../DeviceModals/ModalDevice15";

function CardDevice15(props) {
  const { device, thermostatIPReducer, dispatch } = props;
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const nodeId = device?.nodeId;
  const thermostat = thermostatIPReducer?.thermostats?.[nodeId];

  const isOnThermostatIp = thermostat?.onOff ? thermostat?.onOff === 1 : 0;
  const modeActual = thermostat?.mode;
  const fanActual = thermostat?.fan;
  const realTemp = thermostat?.realTemp;
  const name = thermostat?.name;
  const thermostatErrors = thermostat?.errors;
  const setpointActual = 0;

  const [setpoint, setSetpoint] = useState(parseFloat(setpointActual));

  useEffect(() => {
    if (thermostat) {
      setSetpoint(
        thermostat.setpoint === undefined || thermostat.setpoint === null
          ? thermostat.oldSetpoint === undefined
            ? thermostat.realTemp
            : thermostat.oldSetpoint
          : thermostat.setpoint
      );
    }
  }, [thermostat]);
  const modeIconName = utilsDevice15.getModeIcon(modeActual);
  // const modeIconColor = utilsDevice15.getModeIconColor(modeActual, isOnThermostatIp);

  const fanIconName = fanActual === 0 ? "FanAuto" : `Fan${fanActual}`;

  const dispatchValueChange = (property, value) => {
    dispatch({
      type: "CHANGE_THERMOSTATIP_VALUE",
      data: { nodeId, property, value },
    });
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ModalDevice15 device={device} sendValueServer={dispatchValueChange} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgColor={isOnThermostatIp ? "info" : "white"}
        onClick={() => setOpen(true)}
        sx={({ palette: { background } }) => ({
          background: darkMode && !isOnThermostatIp && background.card,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={0}>
          <MDBox display="flex" sx={{ minWidth: 100 }}>
            <Icons name="Thermometer" size={30} color={darkMode ? "white" : "black"} />
            <MDTypography color={isOnThermostatIp ? "white" : "text"}>
              {realTemp ? `${realTemp?.toFixed(1)}º` : ""}
            </MDTypography>
          </MDBox>

          <MDBox
            mt={-0.5}
            mr={-1}
            sx={{ minWidth: 100 }}
            display="flex"
            justifyContent="flex-end"
            onClick={(event) => {
              event.stopPropagation();
              dispatchValueChange(device.onOffAddress, {
                id: device.onOffAddress,
                value: isOnThermostatIp ? 0 : 1,
              });
            }}
          >
            <Switch
              onChange={() => dispatchValueChange("onOff", isOnThermostatIp ? 0 : 1)}
              checked={isOnThermostatIp ? false : true}
            />
          </MDBox>
        </MDBox>
        <MDBox
          mb={0}
          lineHeight={1}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          alignSelf="center"
          width="50%"
        >
          <MDTypography variant="h4" color={isOnThermostatIp ? "white" : "text"}>
            {setpoint ? `${setpoint.toFixed(1)}ºC` : ""}
          </MDTypography>

          <MDBox
            display="flex"
            height="70px"
            justifyContent="space-evenly"
            alignItems="center"
            minWidth="80px"
          >
            {isOnThermostatIp ? (
              <>
                <Icons name={modeIconName} size={30} color="white" />
                <Icons name={fanIconName} size={30} color="white" />
              </>
            ) : (
              <Icons name="PowerOff" size={30} color={darkMode ? "white" : "black"} />
            )}
          </MDBox>
        </MDBox>

        {thermostatErrors && thermostatErrors.length > 0 && (
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={0}
            lineHeight={1}
          >
            {thermostatErrors.map((error, index) => {
              const errorMessage = Object.values(error)[0];
              return (
                <MDTypography key={index} variant="body1" color="error">
                  {errorMessage ? errorMessage : "ERROR DESCONOCIDO"}
                </MDTypography>
              );
            })}
          </MDBox>
        )}

        <MDBox mt={0} lineHeight={1}>
          <MDTypography
            variant="body2"
            textTransform="capitalize"
            color={isOnThermostatIp ? "white" : "text"}
          >
            {name.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

CardDevice15.propTypes = {
  device: PropTypes.object.isRequired,
  thermostatIPReducer: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  thermostatIPReducer: state.thermostatIPReducer || {},
});

export default connect(mapStateToProps)(memo(CardDevice15));
