import constants from "../constants";

const { UPDATE_DEVICE } = constants;

const initialState = {
  thermostats: {},
};

export default (state = initialState, action) => {
  if (!action) return state;

  const { type, data } = action;

  switch (type) {
    case UPDATE_DEVICE:
      return {
        ...state,
        thermostats: { ...state.thermostats, ...data },
      };

    default:
      return state;
  }
};
