const constants = {
  NEW_ALERT_MESSAGE: "NEW_ALERT_MESSAGE",

  // Used

  UPDATE_MAIN_USER: "UPDATE_MAIN_USER",
  REMOVE_MAIN_USER: "REMOVE_MAIN_USER",
  UPLOAD_MAIN_USER_IMAGE: "UPLOAD_MAIN_USER_IMAGE",
  UPDATE_CLIENTS: "UPDATE_MAIN_USERS",
  REMOVE_CLIENTS: "REMOVE_CLIENTS",
  UPDATE_ONNA_DEVICES: "UPDATE_ONNA_DEVICES",
  GET_ONNA_DEVICES_STATE: "GET_ONNA_DEVICES_STATE",
  UPDATE_ONNA_DEVICES_STATE: "UPDATE_ONNA_DEVICES_STATE",
  REMOVE_ONNA_DEVICES: "REMOVE_ONNA_DEVICES",
  ADD_ONNA_DEVICE_TO_PROJECT: "ADD_ONNA_DEVICE_TO_PROJECT",
  REMOVE_ONNA_DEVICES_FROM_PROJECT: "REMOVE_ONNA_DEVICES_FROM_PROJECT",
  UPDATE_APARTMENTS: "UPDATE_APARTMENTS",
  REMOVE_APARTMENTS: "REMOVE_APARTMENTS",
  UPDATE_PROJECTS: "UPDATE_PROJECTS",
  REMOVE_PROJECTS: "REMOVE_PROJECTS",
  UPDATE_RESERVATIONS: "UPDATE_RESERVATIONS",
  REMOVE_RESERVATIONS: "REMOVE_RESERVATIONS",
  UPDATE_RESERVATION_EVENTS: "UPDATE_RESERVATION_EVENTS",
  REMOVE_RESERVATION_EVENTS: "REMOVE_RESERVATION_EVENTS",
  REMOVE_RESERVATION_EVENT: "REMOVE_RESERVATION_EVENT",
  ADD_RESERVATION_EVENT: "ADD_RESERVATION_EVENT",
  EDIT_RESERVATION_EVENT: "EDIT_RESERVATION_EVENT",
  UPDATE_OCCUPANCY: "UPDATE_OCCUPANCY",
  REMOVE_OCCUPANCY: "REMOVE_OCCUPANCY",
  GET_OCCUPANCY_DATA: "GET_OCCUPANCY_DATA",
  GET_OCCUPANCY_DATA_XLSX: "GET_OCCUPANCY_DATA_XLSX",

  // Modal
  SHOW_MODAL: "SHOW_MODAL",

  // Addresses
  INIT_ADDRESSES_STORE: "INIT_ADDRESSES_STORE",
  EDIT_ADDRESS_STORE: "EDIT_ADDRESS_STORE",
  SEND_VALUE_SERVER: "SEND_VALUE_SERVER",

  // ONNA configuration version
  INIT_VERSION_SERVER: "INIT_VERSION_SERVER",
  INIT_VERSION_STORE: "INIT_VERSION_STORE",

  // ONNA configuration tree
  INIT_CONFIGURATION_SERVER: "INIT_CONFIGURATION_SERVER",
  INIT_CONFIGURATION_STORE: "INIT_CONFIGURATION_STORE",

  // Settings
  SETTINGS_SAVE_DATA: "SETTINGS_SAVE_DATA",
  SETTINGS_SEND_DATA: "SETTINGS_SEND_DATA",
  SEND_DATE_TIME: "SEND_DATE_TIME",
  GET_DATE_TIME: "GET_DATE_TIME",
  UPDATE_APP: "UPDATE_APP",
  GET_IP_ADDRESS: "GET_IP_ADDRESS",
  REBOOT_ONNA: "REBOOT_ONNA",
  SEND_PROTECT_SETTINGS_PAGE: "SEND_PROTECT_SETTINGS_PAGE",
  ASK_NAVIGATE_PERMISSION: "ASK_NAVIGATE_PERMISSION",

  // SEND_GET_USERS_LIST: "SEND_GET_USERS_LIST",
  CHECK_CAPTCHA: "CHECK_CAPTCHA",
  CREATE_MAIN_USER: "CREATE_MAIN_USER",
  SEND_REMOVE_USER_LIST: "SEND_REMOVE_USER_LIST",
  SEND_CHANGE_OPEN_STATE: "SEND_CHANGE_OPEN_STATE",
  CREATE_CLIENT_FIREBASE: "CREATE_CLIENT_FIREBASE",
  REMOVE_CLIENT_FIREBASE: "REMOVE_CLIENT_FIREBASE",

  // ACCESS CONTROL

  ACCESS_CONTROL_CHECK_CREDENTIALS: "ACCESS_CONTROL_CHECK_CREDENTIALS",
  ACCESS_CONTROL_GET_LOCKS: "ACCESS_CONTROL_GET_LOCKS",
  ACCESS_CONTROL_GET_LOCK_DETAIL: "ACCESS_CONTROL_GET_LOCK_DETAIL",
  ACCESS_CONTROL_LOCK_UNLOCK: "ACCESS_CONTROL_LOCK_UNLOCK",
  ACCESS_CONTROL_GET_LOCK_PASSWORDS: "ACCESS_CONTROL_GET_LOCK_PASSWORDS",
  ACCESS_CONTROL_REMOVE_LOCK_PASSWORD: "ACCESS_CONTROL_REMOVE_LOCK_PASSWORD",
  ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT: "ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT",
  ACCESS_CONTROL_LOCK_UNLOCK_CLIENT: "ACCESS_CONTROL_LOCK_UNLOCK_CLIENT",
  ACCESS_CONTROL_CREATE_PASSWORD: "ACCESS_CONTROL_CREATE_PASSWORD",

  // CLOUD APIS

  SET_CLOUD_APIS_APARTMENT: "SET_CLOUD_APIS_APARTMENT",

  // SocketIO
  INIT_SOCKET: "INIT_SOCKET",
  END_SOCKET: "END_SOCKET",
  INIT_CLIENT: "INIT_CLIENT",
  INIT_MAIN_USER_SOCKET: "INIT_MAIN_USER_SOCKET",
  SOCKET_CONNECTION_TYPE: "SOCKET_CONNECTION_TYPE",
  ONNA_SOCKET_STATUS: "ONNA_SOCKET_STATUS",
  SERVER_SOCKET_STATUS: "SERVER_SOCKET_STATUS",
  SOCKET_INFO: "SOCKET_INFO",
  SOCKET_STATUS: "SOCKET_STATUS",

  // Connection
  LAST_CONNECTION: "LAST_IP_CONNECTION",

  // Settings
  WEATHER_CITY: "WEATHER_CITY",
  WEATHER_DATA: "WEATHER_DATA",
  UPDATE_WEATHER_DATA: "UPDATE_WEATHER_DATA",

  // Device
  CHANGE_DEVICE_NAME: "CHANGE_DEVICE_NAME",

  // Schedules
  INIT_SCHEDULES_SERVER: "INIT_SCHEDULES_SERVER",
  SAVE_SCHEDULES_STORE: "SAVE_SCHEDULES_STORE",
  UPDATE_SCHEDULES_STORE: "UPDATE_SCHEDULES_STORE",

  SEND_NEW_SCHEDULE_SERVER: "SEND_NEW_SCHEDULE_SERVER",
  SEND_EDIT_SCHEDULE_SERVER: "SEND_EDIT_SCHEDULE_SERVER",
  SEND_REMOVE_SCHEDULE_SERVER: "SEND_REMOVE_SCHEDULE_SERVER",
  SEND_ENABLE_SCHEDULES_SERVER: "SEND_ENABLE_SCHEDULES_SERVER",

  // Notifications
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  UPDATE_BADGE_NOTIFICATIONS: "UPDATE_BADGE_NOTIFICATIONS",
  REMOVE_NOTIFICATIONS: "REMOVE_NOTIFICATIONS",
  SEND_READ_NOTIFICATION_SERVER: "SEND_READ_NOTIFICATION_SERVER",
  SEND_REMOVE_NOTIFICATION_SERVER: "SEND_REMOVE_NOTIFICATION_SERVER",

  // Reset LogOut
  RESET_APP: "RESET_APP",

  // Alarm
  INIT_ALARM_SERVER: "INIT_ALARM_SERVER",
  UPDATE_ALARM_STORE: "UPDATE_ALARM_STORE",
  SEND_ARM_ALARM_SERVER: "SEND_ARM_ALARM_SERVER",
  SEND_DISARM_ALARM_SERVER: "SEND_DISARM_ALARM_SERVER",
  SEND_CHANGE_PASSWORD_ALARM_SERVER: "SEND_CHANGE_PASSWORD_ALARM_SERVER",

  // Global
  SEND_GROUP_ACTION_SERVER: "SEND_GROUP_ACTION_SERVER",
  SEND_EDIT_ROOM_NAME_SERVER: "SEND_EDIT_ROOM_NAME_SERVER",
  SEND_EDIT_ROOM_COLOR_SERVER: "SEND_EDIT_ROOM_COLOR_SERVER",
  SEND_EDIT_DEVICE_NAME_SERVER: "SEND_EDIT_DEVICE_NAME_SERVER",
  ONNA_GLOBAL_POPUP_MSG: "ONNO_GLOBAL_POPUP_MSG",
  SEND_BUZZER_CLICK_SERVER: "SEND_BUZZER_CLICK_SERVER",

  // Macros

  INIT_MACROS_SERVER: "INIT_MACROS_SERVER",
  SAVE_MACROS_STORE: "SAVE_MACROS_STORE",
  UPDATE_MACROS_STORE: "UPDATE_MACROS_STORE",
  SEND_NEW_MACRO_SERVER: "SEND_NEW_MACRO_SERVER",
  SEND_EDIT_MACRO_SERVER: "SEND_EDIT_MACRO_SERVER",
  SEND_REMOVE_MACRO_SERVER: "SEND_REMOVE_MACRO_SERVER",
  SEND_EXECUTE_MACRO_SERVER: "SEND_EXECUTE_MACRO_SERVER",

  // Intercoms
  INIT_INTERCOMS_SERVER: "INIT_INTERCOMS_SERVER",
  UPDATE_INTERCOMS_STORE: "UPDATE_INTERCOMS_STORE",
  SEND_NEW_CALL_STATE_SERVER: "SEND_NEW_CALL_STATE_SERVER",
  SEND_NEW_VOLUMES_SERVER: "SEND_NEW_VOLUMES_SERVER",
  SEND_TEST_AUDIO_SERVER: "SEND_TEST_AUDIO_SERVER",

  // System
  KNX_STATE_FROM_SERVER: "KNX_STATE_FROM_SERVER",
  INTERNET_STATE_FROM_SERVER: "INTERNET_STATE_FROM_SERVER",

  // Favourites
  NEW_FAVOURITES_FROM_SERVER: "NEW_FAVOURITES_FROM_SERVER",
  SEND_NEW_FAVOURITES_SERVER: "SEND_NEW_FAVOURITES_SERVER",

  // Trends
  GET_TREND_DATA: "GET_TREND_DATA",
  GET_TREND_DATA_XLSX: "GET_TREND_DATA_XLSX",
  SET_TREND_DATA: "SET_TREND_DATA",
  SET_TRENDS: "SET_TRENDS",
  SET_TRENDS_HISTORY: "SET_TRENDS_HISTORY",
  STORE_TREND_HISTORY: "STORE_TREND_HISTORY",
  REMOVE_TREND_DATA: "REMOVE_TREND_DATA",

  // ThermostatIp
  UPDATE_DEVICE: "UPDATE_DEVICE",
  CHANGE_THERMOSTATIP_VALUE: "CHANGE_THERMOSTATIP_VALUE",
};

export default constants;
