/**
 * Thermostat
 */

// prop-types is a library for typechecking of props
import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ModalDevice10 from "layouts/apartment/Devices/DeviceModals/ModalDevice10";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function CardDevice10(props) {
  const {
    device,
    realTempInput,
    onOffStateInput,
    setpointStateInput,
    heatingCoolingChangeStateInput,
    basicCoolingState1BitInput,
    basicCoolingState1ByteInput,
    basicHeatingState1BitInput,
    basicHeatingState1ByteInput,
    additionalCoolingState1BitInput,
    additionalCoolingState1ByteInput,
    additionalHeatingState1BitInput,
    additionalHeatingState1ByteInput,
    color,
    sendValueServer,
  } = props;
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { basicType, heatingCoolingChangeState, additionalStage, demandState } = device;

  const cooling = basicCoolingState1BitInput || basicCoolingState1ByteInput > 0;
  const heating = basicHeatingState1BitInput || basicHeatingState1ByteInput > 0;
  const additionalCooling = additionalCoolingState1BitInput || additionalCoolingState1ByteInput > 0;
  const additionalHeating = additionalHeatingState1BitInput || additionalHeatingState1ByteInput > 0;
  const basicTypeForced = basicType === "heating" ? 1 : 0;
  const heatingCoolingForcedValue = heatingCoolingChangeState
    ? heatingCoolingChangeStateInput
    : basicTypeForced;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ModalDevice10 device={device} sendValueServer={sendValueServer} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgColor={onOffStateInput ? color : "white"}
        onClick={() => {
          setOpen(true);
        }}
        sx={({ palette: { background } }) => ({
          background: darkMode && !onOffStateInput && background.card,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={0}>
          <MDBox display="flex" sx={{ minWidth: 100 }}>
            <Icons name="Thermometer" size={30} color={darkMode ? "white" : "black"} />
            <MDTypography color={onOffStateInput ? "white" : "text"}>{`${realTempInput.toFixed(
              1
            )}ºC`}</MDTypography>
          </MDBox>
          {demandState && heatingCoolingForcedValue === 1 && (
            <MDBox>
              {demandState && (
                <MDBox>
                  <Icons name="Radiator" size={30} color={heating ? "#FA9D27" : "#AAA"} />
                  {additionalStage && additionalHeating && (
                    <Icons name="Fan" size={30} color={additionalHeating ? "#FA9D27" : "#888"} />
                  )}
                </MDBox>
              )}
            </MDBox>
          )}
          {demandState && heatingCoolingForcedValue === 0 && (
            <MDBox>
              {demandState && (
                <MDBox>
                  <Icons name="Snowflake" size={30} color={cooling ? "#FA9D27" : "#AAA"} />
                  {additionalStage && additionalCooling && (
                    <Icons name="Fan" size={30} color={additionalCooling ? "#FA9D27" : "#888"} />
                  )}
                </MDBox>
              )}
            </MDBox>
          )}
          <MDBox
            mt={-0.5}
            mr={-1}
            sx={{ minWidth: 100 }}
            display="flex"
            justifyContent="flex-end"
            onClick={(event) => {
              event.stopPropagation();
              sendValueServer(device.onOffAddress, {
                id: device.onOffAddress,
                value: onOffStateInput ? 0 : 1,
              });
            }}
          >
            <Switch checked={device.onOffInverted ? !onOffStateInput : !!onOffStateInput} />
          </MDBox>
        </MDBox>
        <MDBox
          mb={0}
          lineHeight={1}
          display="flex"
          justifyContent="center"
          height="70px"
          alignItems="center"
        >
          <MDTypography
            variant="h4"
            color={onOffStateInput ? "white" : "text"}
          >{`${setpointStateInput.toFixed(1)}ºC`}</MDTypography>
        </MDBox>
        <MDBox mt={0} lineHeight={1}>
          <MDTypography
            variant="body2"
            textTransform="capitalize"
            color={onOffStateInput ? "white" : "text"}
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice10
CardDevice10.defaultProps = {
  color: "info",
  device: {},
  onOffStateInput: 0,
  realTempInput: 0,
  setpointStateInput: 0,
  heatingCoolingChangeStateInput: 0,
  basicCoolingState1BitInput: 0,
  basicCoolingState1ByteInput: 0,
  basicHeatingState1BitInput: 0,
  basicHeatingState1ByteInput: 0,
  additionalCoolingState1BitInput: 0,
  additionalCoolingState1ByteInput: 0,
  additionalHeatingState1BitInput: 0,
  additionalHeatingState1ByteInput: 0,
};

// Typechecking props for the CardDevice10
CardDevice10.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  onOffStateInput: PropTypes.number,
  realTempInput: PropTypes.number,
  setpointStateInput: PropTypes.number,
  heatingCoolingChangeStateInput: PropTypes.number,
  basicCoolingState1BitInput: PropTypes.number,
  basicCoolingState1ByteInput: PropTypes.number,
  basicHeatingState1BitInput: PropTypes.number,
  basicHeatingState1ByteInput: PropTypes.number,
  additionalCoolingState1BitInput: PropTypes.number,
  additionalCoolingState1ByteInput: PropTypes.number,
  additionalHeatingState1BitInput: PropTypes.number,
  additionalHeatingState1ByteInput: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { knxAddressReducer = [] } = state;
  const { device } = props;
  const addressObject = {};
  const keys = Object.keys(device);
  keys.forEach((key) => {
    if (key.includes("Address") && device[key]) {
      const address = knxAddressReducer.find((foundAddress) => foundAddress.id === device[key]);
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (address) addressObject[newKey] = address.value;
    }
  });

  return addressObject;
};

export default connect(mapStateToProps)(
  memo(CardDevice10, (prevProps, nextProps) => {
    const { device } = prevProps;
    const { nodeId: nodeIdPrev } = device;
    const {
      device: { nodeId },
    } = nextProps;
    const test = [];
    const keys = Object.keys(device);
    keys.forEach((key) => {
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (key.includes("Address") && device[key]) {
        test.push(prevProps[newKey] !== nextProps[key]);
      }
    });
    return test.indexOf(true) === -1 && nodeIdPrev === nodeId;
  })
);
