/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Settings page components
import FormField from "components/FormField";
import DataTable from "components/FormDataTable";

import Modal from "@mui/material/Modal";
import ActionAddEditForm from "layouts/apartment/Devices/ActionAddEditForm";

import {
  sendEditMacroServer,
  sendNewMacroServer,
  sendRemoveMacroServer,
} from "store/actions/macroActions";

const columns = () => {
  const { t } = useTranslation();

  return [
    { Header: t("name"), accessor: "name", width: 20 },
    { Header: t("roomName"), accessor: "roomName", width: 20 },
    { Header: t("value"), accessor: "valueText", width: 20 },
  ];
};

function MacroForm(props) {
  const {
    handleSubmit,
    setOpen,
    initialValues,
    actSendEditMacroServer,
    actSendNewMacroServer,
    actSendRemoveMacroServer,
    roomNodeId,
    macroId,
    actions,
    devices,
    change,
    system,
  } = props;

  const [nameType, setNameType] = useState("moment");
  const [actionType, setActionType] = useState("add");
  const [selectedDevice, setSelectedDevice] = useState({});
  const { t } = useTranslation();

  const submitData = (data) => {
    const { name } = data;
    if (macroId) {
      actSendEditMacroServer({
        roomNodeId,
        macroId,
        macro: {
          ...initialValues,
          name,
          actions,
        },
      });
    } else {
      actSendNewMacroServer({
        roomNodeId,
        macro: {
          name,
          actions,
        },
      });
    }
    setOpen(false);
  };

  const add = () => {
    setNameType("device");
    setActionType("add");
  };

  const edit = (data) => {
    setSelectedDevice(data[0].original);
    setActionType("edit");
    setNameType("device");
  };

  const remove = (data) => {
    const { key } = data[0].original;
    const filterActions = actions.filter((action) => action.key !== key);
    change("actions", filterActions);
  };

  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(submitData)}>
        <Modal
          open={nameType === "device"}
          onClose={() => {
            setNameType("moment");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: "scroll",
          }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "80vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <ActionAddEditForm
              devices={devices}
              selectedDevice={selectedDevice}
              key={selectedDevice?.key}
              onSubmit={(device) => {
                const newActionsData = [...actions];
                if (actionType === "edit") {
                  newActionsData[selectedDevice?.key] = device;
                } else {
                  newActionsData.push({
                    ...device,
                    key: newActionsData.length.toString(),
                  });
                }
                change("actions", newActionsData);
                change("selectedDevice", "");
                setNameType("moment");
              }}
              actionType={actionType}
            />
          </MDBox>
        </Modal>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t("edit")} {t("moment")}
            </MDBox>
            <Grid item pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Field name="name" component={FormField} label={t("Name")} />
                </Grid>
                <Field
                  name="actions"
                  component={DataTable}
                  initialValues={actions}
                  label={t("actions")}
                  {...{
                    table: {
                      columns: columns(),
                    },
                    canAdd: true,
                    canEdit: true,
                    canSelect: true,
                    actionIcon: "arrow_forward_ios",
                    actionColor: "info",
                    add: () => {
                      add();
                    },
                    edit: (data) => {
                      edit(data);
                    },
                    remove: (data) => {
                      remove(data);
                    },
                  }}
                />
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <MDButton type="submit" variant="gradient" color="dark" size="small">
                        {t("Save")}
                      </MDButton>
                    </Grid>
                    {macroId && !system && (
                      <Grid item ml={2}>
                        <MDButton
                          onClick={() => {
                            actSendRemoveMacroServer({ roomNodeId, macroId });
                          }}
                          variant="gradient"
                          color="error"
                          size="small"
                        >
                          {t("Remove")}
                        </MDButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

MacroForm.defaultProps = {
  roomNodeId: "",
  macroId: "",
  actions: [],
  devices: [],
  system: false,
};

MacroForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  // pristine: PropTypes.bool.isRequired,
  system: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  devices: PropTypes.array,
  actSendEditMacroServer: PropTypes.func.isRequired,
  actSendNewMacroServer: PropTypes.func.isRequired,
  actSendRemoveMacroServer: PropTypes.func.isRequired,
  roomNodeId: PropTypes.string,
  macroId: PropTypes.string,
  actions: PropTypes.array,
  change: PropTypes.func.isRequired,
};

const selector = formValueSelector("MacroForm");

const mapStateToProps = (state, props) => {
  const {
    macroData: { roomNodeId, bpNodeId, key },
  } = props;
  const { macroReducer } = state;
  const { macros } = macroReducer;

  let macroData = {};
  const newRoomNodeId = roomNodeId || bpNodeId;
  if (typeof macros === "object" && macros !== null) {
    if (Object.hasOwnProperty.call(macros, bpNodeId)) {
      if (Object.hasOwnProperty.call(macros[bpNodeId].macros, key)) {
        macroData = macros[bpNodeId].macros[key];
      }
    }
  }

  return {
    initialValues: macroData,
    system: macroData.system,
    roomNodeId: newRoomNodeId,
    macroId: key,
    actions: selector(state, "actions"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actSendEditMacroServer: (data) => {
    dispatch(sendEditMacroServer(data));
  },
  actSendNewMacroServer: (data) => {
    dispatch(sendNewMacroServer(data));
  },
  actSendRemoveMacroServer: (data) => {
    dispatch(sendRemoveMacroServer(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "MacroForm", // a unique identifier for this form
  })(MacroForm)
);
