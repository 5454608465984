/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
// import moment from "moment";

// Material Dashboard 2 PRO React components
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
// import MDInput from "components/MDInput";
import { Grid, TextField } from "@mui/material";
import { Field, reduxForm } from "redux-form";
import FormSelector from "components/FormSelector";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getDateTime, actionSettingsSendData } from "store/actions/settingsActions";
import timezones from "./timezones";

function OnnaDeviceInfo({
  handleSubmit,
  settings,
  systemState,
  sendUpdateAppAction,
  actionSettingsSendDataAct,
  getDateTimeAction,
  pristine,
}) {
  const navigate = useNavigate();
  const [timezonesData, setTimezonesData] = useState({
    names: [],
    values: [],
  });
  const [timeString, setTimeString] = useState("");

  const { t } = useTranslation();
  const {
    ipAddress = "127.0.0.1",
    addressType = "dhcp",
    hardwareVersion = "1.0.0",
    appVersion = "1.0.0",
    onnaModel = "unknown",
    onnaId = "unknown",
  } = settings;

  const dateTimeConversion = (dateInfo) => {
    const { milliseconds, offset } = dateInfo;
    const momentOffset = moment.utc(milliseconds).utcOffset(-offset);
    return momentOffset.format("YYYY-MM-DD HH:mm:ss");
  };

  useEffect(() => {
    setTimeout(() => {
      getDateTimeAction((dateInfo) => {
        setTimeString(dateTimeConversion(dateInfo));
      });
    }, 2000);

    const timezonesDataConstructor = {
      names: timezones.map((timezone, index) => ({
        uid: index.toString(),
        name: timezone,
      })),
      values: Array.from({ length: timezones.length }, (_, i) => i.toString()),
    };
    setTimezonesData(timezonesDataConstructor);
  }, []);

  return (
    <form
      onSubmit={handleSubmit((settingsData) => {
        const { timeZone } = settingsData;
        actionSettingsSendDataAct({ timeZone });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })}
    >
      <Card>
        <CardTitle title={t("onnaDevice")} icon="hub" />
        <MDBox pt={3} pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("IP Address")}
                value={ipAddress}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("IP Address Type")}
                value={addressType?.toUpperCase()}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("Hardware Version")}
                value={hardwareVersion}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex">
                <TextField
                  variant="standard"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={t("App Version")}
                  value={appVersion}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <MDBox ml={2}>
                  <MDButton
                    onClick={() => {
                      sendUpdateAppAction(true);
                    }}
                    color="secondary"
                    variant="contained"
                    endIcon={<Icon>autorenew</Icon>}
                  >
                    {t("Update")}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("Onna Model")}
                value={onnaModel}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Onna ID"
                value={onnaId}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="KNX"
                value={systemState?.knxConnectionState ? "TRUE" : "FALSE"}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid marginTop={3} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field
                name="timeZone"
                values={timezonesData.values}
                names={timezonesData.names}
                component={FormSelector}
                label={t("Timezone")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex">
                <TextField
                  variant="standard"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Time"
                  value={timeString}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <MDBox ml={2}>
                  <MDButton
                    onClick={() => {
                      getDateTimeAction((dateInfo) => {
                        setTimeString(dateTimeConversion(dateInfo));
                      });
                    }}
                    color="secondary"
                    variant="contained"
                    endIcon={<Icon>autorenew</Icon>}
                  >
                    {t("Update")}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  size="small"
                  disabled={pristine}
                >
                  {t("Save")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}

OnnaDeviceInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  settings: PropTypes.object,
  systemState: PropTypes.object,
  sendUpdateAppAction: PropTypes.func.isRequired,
  getDateTimeAction: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  actionSettingsSendDataAct: PropTypes.func.isRequired,
};

OnnaDeviceInfo.defaultProps = {
  settings: {},
  systemState: {
    internetConnectionState: false,
    knxConnectionState: false,
  },
};

const mapStateToProps = (state, props) => ({
  initialValues: props.settings,
});

const mapDispatchToProps = (dispatch) => ({
  getDateTimeAction: (cb) => {
    dispatch(getDateTime(cb));
  },
  actionSettingsSendDataAct: (data) => {
    dispatch(actionSettingsSendData(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "BasicInfo", // a unique identifier for this form
    enableReinitialize: true,
    // validate,
  })(OnnaDeviceInfo)
);
