export const getModeValuesAvailable = (t, modeValues) => {
  const modes = [
    { id: 1, label: t("stop"), value: 1 },
    { id: 2, label: t("cool"), value: 2 },
    { id: 3, label: t("heat"), value: 3 },
    { id: 4, label: t("fan"), value: 4 },
    { id: 5, label: t("dry"), value: 5 },
    { id: 7, label: t("auto"), value: 7 },
  ];

  return modes.filter((mode) => modeValues.includes(mode.value));
};

const getModeLabel = (modeActual, modeValues) => {
  const currentMode = modeValues.find((modeValue) => modeValue.value === modeActual);

  const modeLabel = currentMode?.label?.toUpperCase() || "";

  return modeLabel;
};

export const getFanValuesAvailable = (t, type, speeds) => {
  let fanValuesAvailable = [];

  if (type === "Airzone") {
    switch (speeds) {
      case 7:
        fanValuesAvailable = [
          { id: 0, label: t("auto"), value: 0 },
          { id: 1, label: t("veryLow"), value: 1 },
          { id: 2, label: t("low"), value: 2 },
          { id: 3, label: t("mediumLow"), value: 3 },
          { id: 4, label: t("medium"), value: 4 },
          { id: 5, label: t("mediumHigh"), value: 5 },
          { id: 6, label: t("high"), value: 6 },
          { id: 7, label: t("veryHigh"), value: 7 },
        ];
        break;

      case 3:
        fanValuesAvailable = [
          { id: 0, label: t("auto"), value: 0 },
          { id: 1, label: t("low"), value: 1 },
          { id: 2, label: t("medium"), value: 2 },
          { id: 3, label: t("high"), value: 3 },
        ];
        break;

      default:
        break;
    }
  }

  return fanValuesAvailable;
};

const getFanLabel = (actualFanValue, fanValues) => {
  const currentFan = fanValues.find((fanValue) => fanValue.value === actualFanValue);

  const fanLabel = currentFan?.label?.toUpperCase() || "";

  return fanLabel;
};

const getModeIcon = (mode) => {
  let iconName = "";

  if (mode === 1) {
    iconName = "StopMode";
  }

  if (mode === 2) {
    iconName = "Snowflake";
  }

  if (mode === 3) {
    iconName = "Radiator";
  }

  if (mode === 5) {
    iconName = "Drymode";
  }

  if (mode === 4) {
    iconName = "Fanmode";
  }

  if (mode === 7) {
    iconName = "Automode";
  }

  return iconName;
};

export const utilsDevice15 = {
  getModeValuesAvailable,
  getFanValuesAvailable,
  getModeLabel,
  getFanLabel,
  getModeIcon,
};
